.errorContainer {
  width: 100%;
  height: 90vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .code {
    font-size: var(--font-36);
    margin: 0;
    font-weight: bold;
  }

  .description {
    font-size: var(--font-32);
    margin: 0;
    font-weight: bold;
  }

  .message {
    color: var(--gray-900);
  }

  .buttonContainer {
    width: 170px;
    height: var(--u5);
    font-weight: bold;
  }
}
